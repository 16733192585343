<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Users
        </h1>
      </b-col>
      <b-col cols="auto">
        <b-button variant="primary" @click="save">
          Save
        </b-button>
      </b-col>
    </b-row>
    <registration-setup-form v-model="formConfig" />
    <h4 class="mt-5">Example</h4>
    <registration-form :config="formConfig" v-model="exampleData" />
    <h4>Debug:</h4>
    <pre>{{ exampleData }}</pre>
  </b-overlay>
</template>
<script>
import { translateSetting } from "@/utils/translated-setting.util";
import RegistrationSetupForm from "./RegistrationSetupForm.vue";
import RegistrationForm from "../BaseForm.vue";

export default {
  components: { RegistrationSetupForm, RegistrationForm },
  data() {
    return {
      isLoading: false,
      exampleData: {},
      formConfig: [
        {
          title: "Personal info",
          elements: [
            {
              field: "test1",
              label: "test1",
              type: "text",
              caption: "",
              sizes: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
              },
            },
            {
              field: "test2",
              label: "test2",
              type: "number",
              caption: "",
              sizes: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
              },
            },
            {
              field: "test3",
              label: "test3",
              type: "email",
              caption: "",
              sizes: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
              },
            },
            {
              field: "test4",
              label: "test4",
              type: "checkbox",
              values: ["val 1", "val 2", "val 3"],
              caption: "",
              sizes: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
              },
            },
            {
              field: "test5",
              label: "test5",
              type: "radio",
              values: ["val 1", "val 2", "val 3"],
              caption: "",
              sizes: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
              },
            },
            {
              field: "test6",
              label: "test6",
              type: "dropdown",
              values: ["val 1", "val 2", "val 3"],
              caption: "",
              sizes: {
                xs: 12,
                sm: 12,
                md: 6,
                lg: 6,
              },
            },
          ],
        },
      ],
    };
  },
  methods: {
    save() {
      this.isLoading = true;
      this.isLoading = false;
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped></style>
